<template>
  <v-card elevation="6">
    <v-card-title class="py-3">
      <h2
        style="text-transform: uppercase; font-weight: normal; font-size: 20px"
        v-text="$tc('design')"
      />
      <edit-dialog
        v-if="false"
        style_btn="position: absolute; right: 25px"
        :appointment_id="appointment_id"
        :appointment="appointment"
        :design="design"
        @update="$emit('update')"
        :tattooer_id="tattooer_id"
        :token_payment="token_payment"
      ></edit-dialog>
    </v-card-title>

    <v-divider></v-divider>
    <v-simple-table :id="$vuetify.breakpoint.xsOnly ? 'tableSimple' : ''">
      <!--
        <tr>
          <td width="200px">{{ $t("first_name") }}</td>
          <td>{{ design.name || "-" }}</td>
        </tr>
      -->
      <tr>
        <td height="36px">
          {{ $t("appointments.price_desing") }}
        </td>
        <td>
          <design-dialog
            class="mr-4"
            v-if="
              (!appointment.design || appointment.design.price == null) &&
              ($store.getters['auth/isTattooer'] ||
                $store.getters['auth/isStudio'])
            "
            :appointment="appointment"
            :sessions="appointmentDates"
            @update="$emit('update')"
            :disabled="!can_edit"
          ></design-dialog>
          <edit-dialog
            v-else-if="
              appointment.design &&
              ($store.getters['auth/isTattooer'] ||
                $store.getters['auth/isStudio'])
            "
            :appointment_id="appointment.id"
            :appointment="appointment"
            :design="appointment.design"
            :tattooer_id="appointment.tattooer_id"
            :token_payment="appointment.token_payment"
            @update="$emit('update')"
            :disabled="!can_edit"
          ></edit-dialog>
          {{ design && design.price ? $n(design.price, "currency") : "" }}
          {{
            design && design.max_price
              ? " - " + $n(design.max_price, "currency")
              : ""
          }}
        </td>
      </tr>

      <tr>
        <td height="36px">{{ $t("designs.deposit") }}</td>
        <td>
          <!--
            <add-deposit></add-deposit>
          -->
          <EditDeposit
            v-if="appointment.deposit"
            :appointment="appointment"
            @update="$emit('update')"
          />
          <NewDeposit
            v-else
            :appointment="appointment"
            @update="
              $emit('update');
              openSend();
            "
          />
          <SendMail ref="sendMail" hidden :appointment="appointment" />
        </td>
      </tr>
      <tr>
        <td height="36px">{{ $t("designs.token_payment") }}</td>
        <td
          :style="
            $store.getters['auth/isLoggedIn']
              ? ''
              : 'padding-left: 13px !important'
          "
        >
          <!--
          <v-tooltip bottom v-if="appointment.payed">
            <template v-slot:activator="{ on }">
              <v-btn small icon v-on="on" class="mr-2">
                <v-icon small color="success">mdi-check</v-icon>
              </v-btn>
            </template>
            {{ $t("payed") }}
          </v-tooltip>
-->
          <design-dialog-token
            class="mr-4"
            :appointment="appointment"
            :disabled="!can_edit"
            :sessions="appointmentDates"
            @update="
              $emit('update');
              $refs.list.fetchAppointmentTokenPayments();
            "
          ></design-dialog-token>

          <ListDialogToken
            :appointment_id="appointment.id"
            :appointment="appointment"
            :disabled="!can_edit"
            :design="appointment.design"
            :tattooer_id="appointment.tattooer_id"
            :token_payment="appointment.token_payment"
            @update="
              $emit('update');
              fetchAppointmentTokenPayments();
            "
            ref="list"
          ></ListDialogToken>

          <span v-if="appointment.totalAppointmentTokenPayments">{{
            $n(appointment.totalAppointmentTokenPayments, "currency")
          }}</span>

          <!--
          <v-btn
            v-if="$refs.list && !$refs.list.tokenPaymentsPayed"
            class="ml-3"
            outlined
            small
            @click="$refs.list.open()"
            >{{ $t("pay") }}</v-btn
          >
          -->
        </td>
      </tr>
      <tr>
        <td height="36px">{{ $t("appointments.price") }}</td>
        <td>
          <session-prices
            v-if="$store.getters['auth/isLoggedIn']"
            ref="prices"
            @update="$emit('update')"
            :appointmentDates="appointmentDates"
            :appointment="appointment"
            :disabled="!can_edit"
            :appointmentPaymentTokens="tokenPayments"
          ></session-prices>
          <div
            v-if="
              $vuetify.breakpoint.mdAndUp && !$store.getters['auth/isLoggedIn']
            "
            class="ml-md-2"
          >
            {{ totalPrice ? $n(totalPrice, "currency") : "" }}
          </div>
          <span class="ml-2" v-else>{{
            totalPrice ? $n(totalPrice, "currency") : ""
          }}</span>
        </td>
      </tr>
      <tr v-if="false">
        <td height="36px">{{ $t("designs.size") }}</td>
        <td>
          {{
            design && design.size && design.size !== "undefined"
              ? typeof design.size === "object"
                ? design.size.size
                : design.size
              : ""
          }}
        </td>
      </tr>
      <tr>
        <td height="36px">{{ $t("expenses.pendings_customer") }}</td>
        <td style="padding-left: 15px !important">
          {{ $n(pendingCustomer, "currency") }}

          <v-tooltip
            bottom
            v-if="pendingCustomer == 0 && appointmentDates.length > 0"
          >
            <template v-slot:activator="{ on }">
              <v-btn small icon v-on="on" class="mr-2">
                <v-icon small color="success">mdi-check</v-icon>
              </v-btn>
            </template>
            {{ $t("payed") }}
          </v-tooltip>
          <span v-else-if="appointmentDates.length == 0"></span>
          <PayPendingCustomer
            v-else-if="false"
            :appointment="appointment"
            @update="$emit('update')"
          />
        </td>
      </tr>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DesignCard",
  data() {
    return {
      tokenPayments: [],
    };
  },
  props: [
    "design",
    "tattooer_id",
    "token_payment",
    "appointment_id",
    "appointment",
    "appointmentDates",
    "can_edit",
  ],

  components: {
    DesignDialog: () => import("@/components/appointments/design/Dialog"),
    DesignDialogToken: () =>
      import("@/components/appointments/design/DialogTokenPayment"),
    EditDialog: () => import("@/components/appointments/design/EditDialog"),
    SessionPrices: () => import("@/components/appointments/sessions/Prices"),
    EditDialogToken: () =>
      import("@/components/appointments/design/EditDialogTokenPayment"),
    PayTokenPayment: () => import("@/components/appointments/PayTokenPayment"),
    AddPendingCustomer: () =>
      import("@/components/appointments/AddPendingCustomer"),
    PayPendingCustomer: () =>
      import("@/components/appointments/PayPendingCustomer"),
    NewDeposit: () => import("@/components/appointments/design/deposit/New"),
    EditDeposit: () => import("@/components/appointments/design/deposit/Edit"),
    ListDialogToken: () => import("@/components/appointments/design/List"),
    SendMail: () => import("@/components/mails/SendMail"),
  },
  mounted() {
    this.fetchAppointmentTokenPayments();
  },
  methods: {
    calculateCommissions() {
      console.log("CALCULAR");
      this.$refs.prices.calculateCommissions();
    },
    openPriceList() {
      console.log("OPEN PRICE LIST");
      this.$refs.prices.dialog = true;
    },

    liq(item) {
      let liq = false;
      if (item.expenses.length) {
        item.expenses.forEach((ex) => {
          if (ex.pending == 0) {
            liq = true;
          }
        });
      }
      return liq;
    },

    openSend() {
      this.$refs.sendMail.open("DEPÓSITO");
    },
    ...mapActions("appointments", ["getAppoinmentsTokenPayments"]),

    //FETCH appointmentstokenpayments
    fetchAppointmentTokenPayments() {
      console.log("fetchAppointmentTokenPayments");
      console.log(this.appointment);
      console.log(this.appointment.id);
      this.getAppoinmentsTokenPayments(this.appointment.id).then((data) => {
        this.tokenPayments = data;
        console.log(this.tokenPayments);
      });
    },
  },
  computed: {
    totalPrice() {
      let total = 0;
      this.appointmentDates.forEach((x) => (total += +x.sesion_price));
      console.log("TOTAL", total);
      return total;
    },

    payment_appointment() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "payment_appointment"
      );
      if (s) return s.value === "1";
      return false;
    },
    pendingCustomer() {
      let pending = 0;
      let alreadyPayed = 0; //PARTIALS
      let totalAppointmentTokenPayments = 0; //PAGAS Y SEÑALES
      this.appointmentDates.forEach((x) => {
        alreadyPayed += +x.alreadyPayed;
        console.log("alreadyPayed", alreadyPayed);
      });
      this.tokenPayments.forEach((t) => {
        if (t.payed) totalAppointmentTokenPayments += t.price;
        console.log(
          "totalAppointmentTokenPayments",
          totalAppointmentTokenPayments
        );
      });
      pending =
        this.totalPrice -
        totalAppointmentTokenPayments -
        alreadyPayed -
        this.appointment.deposit;
      if (pending < 0) pending = 0;
      console.log("PENDING", pending);
      return pending;
    },
  },
};
</script>
<style lang="sass" scoped>
.slideImg
  margin: auto
  display: block
.v-data-table
  td
    padding: 3px 8px !important
  td:first-child
    color: var(--v-gris1-base)
</style>
