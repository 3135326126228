var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"6"}},[_c('v-card-title',{staticClass:"py-3"},[_c('h2',{staticStyle:{"text-transform":"uppercase","font-weight":"normal","font-size":"20px"},domProps:{"textContent":_vm._s(_vm.$tc('design'))}}),(false)?_c('edit-dialog',{attrs:{"style_btn":"position: absolute; right: 25px","appointment_id":_vm.appointment_id,"appointment":_vm.appointment,"design":_vm.design,"tattooer_id":_vm.tattooer_id,"token_payment":_vm.token_payment},on:{"update":function($event){return _vm.$emit('update')}}}):_vm._e()],1),_c('v-divider'),_c('v-simple-table',{attrs:{"id":_vm.$vuetify.breakpoint.xsOnly ? 'tableSimple' : ''}},[_c('tr',[_c('td',{attrs:{"height":"36px"}},[_vm._v(" "+_vm._s(_vm.$t("appointments.price_desing"))+" ")]),_c('td',[(
              (!_vm.appointment.design || _vm.appointment.design.price == null) &&
              (_vm.$store.getters['auth/isTattooer'] ||
                _vm.$store.getters['auth/isStudio'])
            )?_c('design-dialog',{staticClass:"mr-4",attrs:{"appointment":_vm.appointment,"sessions":_vm.appointmentDates,"disabled":!_vm.can_edit},on:{"update":function($event){return _vm.$emit('update')}}}):(
              _vm.appointment.design &&
              (_vm.$store.getters['auth/isTattooer'] ||
                _vm.$store.getters['auth/isStudio'])
            )?_c('edit-dialog',{attrs:{"appointment_id":_vm.appointment.id,"appointment":_vm.appointment,"design":_vm.appointment.design,"tattooer_id":_vm.appointment.tattooer_id,"token_payment":_vm.appointment.token_payment,"disabled":!_vm.can_edit},on:{"update":function($event){return _vm.$emit('update')}}}):_vm._e(),_vm._v(" "+_vm._s(_vm.design && _vm.design.price ? _vm.$n(_vm.design.price, "currency") : "")+" "+_vm._s(_vm.design && _vm.design.max_price ? " - " + _vm.$n(_vm.design.max_price, "currency") : "")+" ")],1)]),_c('tr',[_c('td',{attrs:{"height":"36px"}},[_vm._v(_vm._s(_vm.$t("designs.deposit")))]),_c('td',[(_vm.appointment.deposit)?_c('EditDeposit',{attrs:{"appointment":_vm.appointment},on:{"update":function($event){return _vm.$emit('update')}}}):_c('NewDeposit',{attrs:{"appointment":_vm.appointment},on:{"update":function($event){_vm.$emit('update');
              _vm.openSend();}}}),_c('SendMail',{ref:"sendMail",attrs:{"hidden":"","appointment":_vm.appointment}})],1)]),_c('tr',[_c('td',{attrs:{"height":"36px"}},[_vm._v(_vm._s(_vm.$t("designs.token_payment")))]),_c('td',{style:(_vm.$store.getters['auth/isLoggedIn']
              ? ''
              : 'padding-left: 13px !important')},[_c('design-dialog-token',{staticClass:"mr-4",attrs:{"appointment":_vm.appointment,"disabled":!_vm.can_edit,"sessions":_vm.appointmentDates},on:{"update":function($event){_vm.$emit('update');
              _vm.$refs.list.fetchAppointmentTokenPayments();}}}),_c('ListDialogToken',{ref:"list",attrs:{"appointment_id":_vm.appointment.id,"appointment":_vm.appointment,"disabled":!_vm.can_edit,"design":_vm.appointment.design,"tattooer_id":_vm.appointment.tattooer_id,"token_payment":_vm.appointment.token_payment},on:{"update":function($event){_vm.$emit('update');
              _vm.fetchAppointmentTokenPayments();}}}),(_vm.appointment.totalAppointmentTokenPayments)?_c('span',[_vm._v(_vm._s(_vm.$n(_vm.appointment.totalAppointmentTokenPayments, "currency")))]):_vm._e()],1)]),_c('tr',[_c('td',{attrs:{"height":"36px"}},[_vm._v(_vm._s(_vm.$t("appointments.price")))]),_c('td',[(_vm.$store.getters['auth/isLoggedIn'])?_c('session-prices',{ref:"prices",attrs:{"appointmentDates":_vm.appointmentDates,"appointment":_vm.appointment,"disabled":!_vm.can_edit,"appointmentPaymentTokens":_vm.tokenPayments},on:{"update":function($event){return _vm.$emit('update')}}}):_vm._e(),(
              _vm.$vuetify.breakpoint.mdAndUp && !_vm.$store.getters['auth/isLoggedIn']
            )?_c('div',{staticClass:"ml-md-2"},[_vm._v(" "+_vm._s(_vm.totalPrice ? _vm.$n(_vm.totalPrice, "currency") : "")+" ")]):_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.totalPrice ? _vm.$n(_vm.totalPrice, "currency") : ""))])],1)]),(false)?_c('tr',[_c('td',{attrs:{"height":"36px"}},[_vm._v(_vm._s(_vm.$t("designs.size")))]),_c('td',[_vm._v(" "+_vm._s(_vm.design && _vm.design.size && _vm.design.size !== "undefined" ? typeof _vm.design.size === "object" ? _vm.design.size.size : _vm.design.size : "")+" ")])]):_vm._e(),_c('tr',[_c('td',{attrs:{"height":"36px"}},[_vm._v(_vm._s(_vm.$t("expenses.pendings_customer")))]),_c('td',{staticStyle:{"padding-left":"15px !important"}},[_vm._v(" "+_vm._s(_vm.$n(_vm.pendingCustomer, "currency"))+" "),(_vm.pendingCustomer == 0 && _vm.appointmentDates.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","icon":""}},on),[_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-check")])],1)]}}],null,false,2277684659)},[_vm._v(" "+_vm._s(_vm.$t("payed"))+" ")]):(_vm.appointmentDates.length == 0)?_c('span'):(false)?_c('PayPendingCustomer',{attrs:{"appointment":_vm.appointment},on:{"update":function($event){return _vm.$emit('update')}}}):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }